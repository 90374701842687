@font-face {
  font-family: "Google-Sans";
  src: url("https://fonts.gstatic.com/s/googlesans/v14/4UabrENHsxJlGDuGo1OIlLU94YtzCwY.woff2");
}


@font-face {
  font-family: "Google-Sans-Bold";
  src: url("https://fonts.gstatic.com/s/googlesans/v14/4UabrENHsxJlGDuGo1OIlLV154tzCwY.woff2");
}


.App {
  font-family: Google-Sans;
  text-align: center;
}


.MuiTypography-body1{
  font-family:Google-Sans!important;
}


.fileDiv{
  padding:100px;
  /* border:2px solid rgba(0, 0, 0, 0.295); */
  position:relative;
  color: #128DFF;
  /* border-radius:10px; */
  text-align:center;
  cursor:pointer;
  font-family: Google-Sans-Bold;
  font-size: 2em;
  margin-top: 150px;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
  border: 0.1px solid rgba(124, 123, 123, 0.075);

  -webkit-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  -moz-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);



}


.fileDiv:hover{
  background-color: rgba(18, 141, 255, 0.116);
  
}

.hide_file{
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 30px;
  width: 100%;
}



.submitButton{
  background-color: #128DFF;
  border: none;
  font-family: Google-Sans;
  color:white;
  padding: 25px 50px;
  border-radius: 10px;
  font-size: 1.1em;

  -webkit-box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);
  -moz-box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);
  box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);


}


.submitbutton{
  background-color: #128DFF;
  border: none;
  font-family: Google-Sans;
  color:white;
  /* padding: 15px 25px; */
  margin: 2px;
  height: 55px;
  width: 20%;
  /* position: left; */
  top: 15px;
  border-radius: 0px;
  font-size: 1.1em;

  -webkit-box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);
  -moz-box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);
  box-shadow: 115px 115px 57px -118px rgba(38,56,255,1);


}





.submitButton:hover{

  color: white;
  border: 3px #128DFF;
  -webkit-box-shadow: 131px 123px 36px -118px rgba(38,56,255,1);
  -moz-box-shadow: 131px 123px 36px -118px rgba(38,56,255,1);
  box-shadow: 131px 123px 36px -118px rgba(38,56,255,1);
}




/* .MuiListItemIcon-root{

  min-width: 30px!important;
} */

.MuiListItem-gutters{
  
  -webkit-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  -moz-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  margin-bottom: 5px!important;
  border-radius: 0px 12px 12px 0px!important;
  padding: 50px 0px!important;
  
}

.fiftybutton{
  margin-top:19px; 
  width: 50%;
  background-color:rgb(255, 255, 255);
  color:rgb(0, 0, 0);
  border: 0.1px solid rgba(124, 123, 123, 0.075);
  padding: 20px;
  font-family:Google-Sans;
  text-decoration: none!important;


  -webkit-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  -moz-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);

}
a{
  text-decoration: none!important;
  color: white;

}

.fileupload{
  margin-top: 150px;
}

.fiftybutton:hover{
  background-color: rgba(18, 141, 255, 0.116);
}


.inputbox{
  background-color: white;
  border: 0.1px solid rgba(87, 87, 87, 0.075);
  width: 65%;
  height: 100%;
  height: 50px;
  font-family: Google-Sans;
  font-size: 30PX;
  -webkit-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  -moz-box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3);
  box-shadow: 26px 24px 14px -23px rgba(184,184,184,0.3)!important;
}







.Link{
  text-decoration: none;
  color: black;
}

.Link:hover{
  color: blue!important;
}




body {
  margin: 0;
  fill:red;
}



.navbar-l{
  background-color: #128DFF;
  border: none;
  width: 33.33%;
  font-family:Google-Sans-Bold;
  font-size: 1.9em;
  color: white;
  padding: 30px 30px;
  
  
}
svg{
  fill:blue;
}


.navbar-f{
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 100%;
  margin-bottom:30px;

}

.navbar-l:hover{
  background-color: rgba(18, 141, 255, 0.404);
}